
@import '~@/assets/sass/design.sass'

.modal
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  display: flex
  align-items: center
  justify-content: center
  background-color: transparentize($c-black, 0.5)
  &Content
    padding: $s-xl
    background: $c-pickled-bluewood-blue
    border-radius: $base-border-radius
    box-shadow: $base-shadow
  &Footer
    margin-top: $s-xl
    text-align: right

.inputArea
  display: flex
  align-items: center

.label
  margin-bottom: 0
  font-size: 14px

.code
  min-width: 250px
  height: 40px
  padding: 0
  border: none
  border-radius: $base-border-radius
  box-shadow: $base-shadow

.button
  padding: $base-spacing / 3 $base-spacing
  color: $c-white
  cursor: pointer
  border-radius: $base-border-radius
  box-shadow: $base-shadow
  transition: all .1s ease-out
  &:hover
    opacity: 0.9
  &:active
    transform: scale(0.9)

.confirmButton
  background: $c-acc-green
  &:disabled
    color: $text-faded
    background: $c-acc-green-dark

.closeButton
  background: $c-acc-red

.button + .button
  margin-left: $base-spacing
