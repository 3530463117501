
@import '~@/assets/sass/design.sass'

.menububble
  position: absolute
  z-index: 100
  display: flex
  align-items: center
  justify-content: center
  min-width: 330px
  max-width: 100%
  min-height: 50px
  padding: $s-xs $base-spacing
  background: $dark-gradient
  border-radius: $base-border-radius
  box-shadow: $base-shadow
  opacity: 0
  transition: opacity .3s ease-in-out
  transform: scale(0)
  &IsActive
    opacity: 1
    transform: scale(1)
  &Button
    display: flex
    cursor: pointer
  &Icon
    margin-left: $base-spacing    
  &Footer
    margin: $s-xl 0
    text-align: right

.linkBubbleButtons
  display: grid
  grid-template-columns: 1fr 1fr
  align-items: center
  margin: $s-l 0

.menububbleButton:hover
  color: $c-acc-green

.button
  padding: $base-spacing / 3 $base-spacing
  color: $c-white
  cursor: pointer
  border-radius: $base-border-radius
  box-shadow: $base-shadow
  transition: all .1s ease-out
  &:hover
    opacity: 0.9
  &:active
    transform: scale(0.9)

.confirmButton
  background: $c-acc-green
  &:disabled
    color: $text-faded
    background: $c-acc-green-dark

.closeButton
  background: $c-acc-red

.button + .button
  margin-left: $base-spacing

