
@import '~@/assets/sass/design';

.tab {
    display: inline-block;
    width: 100%;
    min-height: 400px;
    padding: $s-m;
    color: $c-white;
    background-color: transparentize($c-pickled-bluewood-blue, 0.05);
    border-radius: 6px;
}
