
@use '~@/assets/sass/design.sass' as *;
@use "sass:math";

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.resultsContainer {
  position: absolute;
  min-width: 330px;
  max-height: 300px;
  padding: $base-spacing;
  overflow: auto;
  text-align: left;
  background: $limed-spruce;
}

.list {
  padding: 0;
  margin: 0;

  ~.list {
    margin-top: $base-spacing;
  }
}

.listItem {
  font-size: 12px;
  list-style: none;
  cursor: pointer;

  &:hover {
    color: $c-acc-green;
  }

  ~.listItem {
    margin-top: math.div($base-spacing, 3);
  }
}

.title {
  font-size: 12px;
  font-weight: bold;
}

.clearButton {
  margin-left: math.div($base-spacing, 2);
  line-height: 1;
  cursor: pointer;
}
