
@import '~@/assets/sass/design.sass'

.separator
  display: inline
  border-left: 1px solid transparentize($text-faded, 0.8)
.menubar
  position: sticky
  top: 0
  z-index: $z-page
  padding: $base-spacing
  margin: 0 $s-xxs $s-m
  background: transparentize($c-pickled-bluewood-blue, 0.05)
  border-radius: 6px
  &Bottom
    margin-top: $s-s
  &Button
    display: inline-flex
    font-size: $s-m
    font-weight: 700
    color: $text-faded
    cursor: pointer
    &:hover
      color: $c-acc-green-dark
    &.isActive
      color: $c-acc-green
.menubarButton~.menubarButton
  margin-left: $s-m
.editor
  height: auto
  min-height: 400px
  max-height: unset
  font-size: 1rem

.library
  max-height: 90vh
  overflow: auto
