
.vue-yoast-assessor-item {
  margin-bottom: 5px;
}
.vue-yoast-assessor-text {
  vertical-align: middle;
  padding: 0 10px;
}
.vue-yoast-assessor-badge {
  vertical-align: middle;
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50%;
}
.vue-yoast-assessor-item-feedback .vue-yoast-assessor-badge{
  background-color: gray;
}
.vue-yoast-assessor-item-bad .vue-yoast-assessor-badge{
  background-color: #cf0101;
}
.vue-yoast-assessor-item-ok .vue-yoast-assessor-badge{
  background-color: #f09c03;
}
.vue-yoast-assessor-item-good .vue-yoast-assessor-badge{
  background-color: green;
}

.vue-yoast-snippet-preview span{
  font-family: arial,sans-serif !important;
  font-style: normal;
}
.vue-yoast-snippet-preview-title{
  margin: 0;
  color: #1e0fbe;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  text-decoration: none;
  box-sizing: border-box;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  max-width: 600px;
  vertical-align: top;
  text-overflow: ellipsis;
}
.vue-yoast-snippet-preview-url {
  width: 600px;
  line-height: 1;
  white-space: nowrap;
  box-sizing: border-box;
  white-space: nowrap;
}
.vue-yoast-snippet-preview-url span{
  display: inline-block;
  overflow: hidden;
  min-width: 20px;
  max-width: 586px;
  font-size: 14px;
  line-height: 16px;
  vertical-align: top;
  text-overflow: ellipsis;
  font-style: normal;
  color: #006621;
  direction: ltr;
}
.vue-yoast-snippet-preview-description {
  clear: both;
  min-height: 20px;
  width: 600px;
}
.vue-yoast-snippet-preview-description span {
  color: #545454;
  font-size: 13px;
  line-height: 1.4;
  font-style: normal;
}
